<template>
    <div>
        <div class="modal_header">
            <el-row>
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple">
                        <div class="title_h">
                            <!-- {{ $t("message.something") }} -->
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple-light">
                        <el-row class="button_r">
                            <el-button :loading="loadingButton" :disabled="loadingButton ? true : false"
                                @click="submit(true)" type="success">{{ $t("message.save_and_close") }}</el-button>
                            <el-button @click="resetForm('form')">{{
                                $t("message.close")
                            }}</el-button>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>

        <el-form ref="form" status-icon :rules="rules" :label-position="'right'" class="aticler_m">
            <el-row :gutter="20">
                <el-col :sm="12">
                    <el-form-item :label="columns.name.title">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :sm="24">
                    <el-form-item v-for="checkbox in items" :key="checkbox.id">
                        <el-checkbox v-model="checkbox.checked" :label="checkbox.name" size="medium"></el-checkbox>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
export default {
    mixins: [form],
    data() {
        return {
            defaultProps: {
                children: "children",
                label: "name",
            },
            items: [],
        };
    },
    watch: {
        reloadModel: {
            handler: function () {
                if (this.reloadModel) {
                    this.loadModel()
                }
            },
            deep: true
        },
    },
    mounted() {
        this.getColumns().then((res) => {
            this.excel_columns.forEach((element, idx) => {
                this.items.push({
                    id: element.id,
                    name: element.name,
                    checked: false,
                });
            });

            this.model.items.forEach(element => {
               const item = this.items.find(e => e.id === element.excel_report_column_id)

               if (item) item.checked = true;
            })
        });
    },
    computed: {
        ...mapGetters({
            excel_columns: "excelColumns/excel_columns",
            list: "excelColumns/list",
            rules: "excelColumns/rules",
            model: 'excelColumns/model',
            columns: "excelColumns/columns",
        }),
    },
    methods: {
        ...mapActions({
            getColumns: "excelColumns/getColumns",
            save: "excelColumns/update",
        }),
        loadModel() {
            this.form = JSON.parse(JSON.stringify(this.model));
        },
        submit() {
            this.loadingButton = true;
            this.form.items = this.items.map((obj) => obj.id);
            this.save(this.form)
                .then((res) => {
                    this.loadingButton = false;
                    this.resetForm("form");
                    this.items = [];
                    this.parent().listChanged();
                    this.$notify({
                        title: "Ошибка",
                        type: "success",
                        offset: 130,
                        message: res.message,
                    });
                })
                .catch((err) => {
                    this.loadingButton = false;
                    this.$notify({
                        title: "Ошибка",
                        type: "error",
                        offset: 130,
                        message: err.error.message,
                    });
                });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.$emit("c-close", { drawer: "drawerUpdate" });
        },
    },
};
</script>
  